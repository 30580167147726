
import {Vue, Component, Prop } from 'vue-property-decorator'
import SignUpFormLegacy from '@/components/SignUpFormLegacy.vue'
import app from '@/store/modules/app'
import { SplitFeatureFlag } from '@/utils/enum'
import { CustomerValidatePayload, CustomerValidateResult } from '@/models/dto'
import auth from '@/services/auth'
import MainWithSidePanelSignupLayout from '@/layouts/MainWithSidePanelSignupLayout.vue'
import SignUpSidebar from '@/components/SignUpSidebar.vue'

@Component({
  metaInfo() {
    return {
      title: app.title,
      titleTemplate: `${this.$t('signup.TITLE')} - %s`,
    }
  },
  components: {
    MainWithSidePanelSignupLayout,
    SignUpSidebar,
    SignUpFormLegacy,
  },
})
export default class SignUp extends Vue {
  @Prop({ type: Boolean, required: true }) readonly isValidCustomer: boolean
  @Prop({ type: Object, required: true }) readonly customer: CustomerValidateResult

}
